  .AccordionsContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1vw;
  }

  .Achievements h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 30px;
    font-weight: 550;
    line-height: 32px;
    text-align: left;
    padding: 1% 0;
  }

  #achivmentstitle {
    animation: animate 0.7s ease-out;
    padding-top: 90px;
  }

  #achivmentstitle::before {
    content: "";
    display: inline-block;
    width: 18px;
    height: 4px;
    position: relative;
    background-color: #faaa4d;
    left: -10px;
    top: -10px;
  }

  #endAchievements {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 600;
    padding: 3% 0;
  }

  .Achievements h1 a {
    color: #ffffff;
    font-weight: 300;
  }

  @media screen and (max-width: 850px) {
    .AchievementConteiner {
      padding-top: 80px;
    }

    .Achievements h1 {
      font-size: 20px;
      padding: 10px 2px;
    }

    #achivmentstitle::before {
      left: -5px;
      top: -5px;
      width: 13px;
      height: 3px;
    }

    #achivmentstitle {
      padding-top: 0px;
      padding-bottom: 20px;
    }
  }

  .BottomAchivment {
    padding-top: 2.5%;
  }

  .BottomAchivment button {
    padding: 17px 5%;
    border-radius: 0;
    cursor: pointer;
    border: 1px solid #1c2752;
    background-color: #1c2752;
    color: #fff;
  }

  .BottomAchivment button:hover {
    background-color: #fff;
    color: #1c2752;
    transition: 0.2s linear all;
  }
  @media screen and (max-width:1600px) {
    .AccordionsContent{
      gap: 1.2vw;
    }
  }
  @media screen and (max-width:520px) {
    .AccordionsContent{
      gap: 2vw;
    }
  }
  @media screen and (max-width:450px) {
    .AccordionsContent{
      gap: 3vw;
    }
  }