.AchievementsContents {
  display: flex;
  justify-content: flex-start;
  gap: 1.5%;
  flex-wrap: wrap;
  padding-bottom: 2%;
  padding: 0% 1%;
  /* grid-template-columns: repeat(auto-fit, minmax(200px, .5fr)); */
}

#panel-header h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
}

.AchievementsCards {
  border: 1px solid transparent;
  width: calc(89.5% / 7);
  margin-top: 15px;
  margin-bottom: 1%;
  height: auto;
  position: relative;
}

.AchievementsCards img {
  width: 100%;
  max-height: 320px;
  min-height: 320px;
}

.AchievementsCards p {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  padding-top: 5%;
  color: #4b4b4b;
}

.css-pwcg7p-MuiCollapse-root {
  height: auto;
  position: relative;
}

.css-1e48za3-MuiAccordionDetails-root {
  padding: 0px 16px 16px 8px !important;
}

@media screen and (max-width:1950px) {
  .AchievementsCards img {
    width: 100%;
    max-height: 310px;
    min-height: 310px;
  }
}
@media screen and (max-width: 1750px) {
  .AchievementsCards img {
    width: 100%;
    max-height: 290px;
    min-height: 290px;
  }
}

@media screen and (max-width: 1610px) {
  .AchievementsCards img {
    width: 100%;
    max-height: 280px;
    min-height: 280px;
  }
}

@media screen and (max-width: 1510px) {
  .AchievementsCards img {
    width: 100%;
    max-height: 280px;
    min-height: 280px;
  }

  .AchievementsCards {
    width: calc(91% / 6);
  }
}

@media screen and (max-width: 1310px) {
  .AchievementsCards img {
    max-height: 235px;
    min-height: 235px;
  }
}

@media screen and (max-width: 1210px) {
  .AchievementsCards img {
    max-height: 250px;
    min-height: 250px;
  }
}

@media screen and (max-width: 1110px) {
  .AchievementsCards {
    width: calc(92.5% / 5);
  }

  .AchievementsCards img {
    max-height: 275px;
    min-height: 275px;
  }
}

@media screen and (max-width: 1000px) {
  .AchievementsCards img {
    max-height: 250px;
    min-height: 250px;
  }
}

@media screen and (max-width: 910px) {
  .AchievementsCards img {
    max-height: 260px;
    min-height: 260px;
  }

  .AchievementsCards {
    width: calc(94% / 4);
  }
}

@media screen and (max-width: 810px) {
  .AchievementsCards img {
    max-height: 240px;
    min-height: 240px;
  }
}

@media screen and (max-width: 710px) {
  .AchievementsCards {
    width: calc(95.5% / 3);
  }

  .AchievementsCards img {
    max-height: 270px;
    min-height: 270px;
  }
}

@media screen and (max-width: 610px) {
  .AchievementsCards img {
    max-height: 230px;
    min-height: 230px;
  }
}

@media screen and (max-width: 520px) {
  .AchievementsCards {
    width: calc(98% / 2);
  }

  .AchievementsCards img {
    max-height: 294px;
    min-height: 294px;
  }
}

@media screen and (max-width: 450px) {
  .Achievements h1 {
    font-size: 20px;
  }

  .AchievementsCards img {
    max-height: 260px;
    min-height: 260px;
  }

  #panel-header h2 {
    font-size: 18px !important;
  }

  #endAchievements {
    font-size: 20px;
  }
}

@media screen and (max-width: 376px) {
  .AchievementsCards img {
    max-height: 210px;
    min-height: 210px;
  }
}

@media screen and (max-width: 400px) {
  .Achievements h1 {
    font-size: 18px;
  }

  #panel-header h2 {
    font-size: 16px !important;
  }

  #endAchievements {
    font-size: 18px;
  }
}

@media screen and (max-width: 1300px) {
  .AchievementsCards p {
    font-size: 12px;
  }
}