  .feedback-form-container {
    position: fixed;
    bottom: 19%;
    right: 8%;
    overflow: hidden;
    width: 0;
    font-family: "Montserrat", sans-serif;
    transition: transform 0.3s ease-out, opacity 0.2s ease-out;
    transform: translateY(15px);
    opacity: 0;
    z-index: 2000;
  }

  .feedback-form-container.visible {
    width: 35%;
    opacity: 1;
    transform: translateY(0);
    display: block;
  }

  .feedback-form {
    border-radius: 8px;
    background-color: #eeeeee;
  }

  .feedback-form form {
    padding: 3%;
  }

  .feedback-title {
    background-color: #1c2752;
    padding: 3% 2%;
    overflow: hidden;
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .feedback-title p {
    color: white;
    font-size: 25px;
    font-weight: 600;
  }

  .HeaderFeedBackInputs {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .HeaderFeedBackInputs .form-group {
    width: 48%;
  }

  .HeaderFeedBackInputs .form-group input {
    width: 100%;
    padding: 5% 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .feedback-form .form-group textarea {
    font-size: 18px !important;
  }

  .feedback-form .form-group {
    padding-bottom: 15px;
  }

  .feedback-form .form-group label {
    display: block;
    margin-bottom: 5px;
  }

  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 2.5% 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }

  .feedback-close-btn {
    color: #ffffff;
    font-size: 30px;
    display: none !important;
    font-weight: 600;
    position: absolute;
    top: 25%;
    right: 5%;
    cursor: pointer;
  }

  .feedback-form .form-group input::placeholder,
  .feedback-form .form-group textarea::placeholder {
    color: #aaa;
  }

  .form-group input {
    margin-bottom: 10px;
  }

  .form-group textarea {
    resize: vertical;
    height: 120px;
    font-size: 18px;
  }

  .feedback-sumbit {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 2%;
  }

  .feedback-form .feedback-sumbit button {
    width: 40%;
    padding: 2.5% 0;
    background-color: #1c2752;
    border: 1px solid #1c2752 !important;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
  }

  .errors {
    font-size: 12px;
    color: red;
    position: relative;
    top: -7px;
    right: -5px;
  }

  .success {
    background-color: #e1fddf;
    position: absolute;
    width: 100%;
    bottom: 0%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .error {
    background-color: #f7d3d3;
    position: absolute;
    bottom: 0%;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .modal-content .success-icon {
    color: green;
    margin-bottom: 3%;
    font-size: 80px;
  }

  .modal-content .error-icon {
    color: red;
    margin-bottom: 3%;
    font-size: 80px;
  }

  .modal-content p {
    width: 90%;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    padding-bottom: 5%;
  }

  .modal-content button {
    width: 35%;
    padding: 2.5% 0;
    font-size: 16px;
    cursor: pointer;
    background-color: #1c2752;
    border-radius: 1px solid #1c2752;
    color: #ffffff;
  }

  .modal-content button:hover {
    background-color: #ffffff;
    color: #1c2752;
    transition: .2s linear all;
  }

  .feedback-form button:hover {
    background-color: #ffffff;
    color: #1c2752;
    transition: 0.2s all linear;
  }

  @media screen and (max-width: 1300px) {
    .feedback-form-container.visible {
      width: 45%;
    }
  }

  @media screen and (max-width: 1000px) {
    .feedback-form-container.visible {
      width: 50%;
    }

    .feedback-title p {
      font-size: 17px;
      padding: 3% 5px;
    }

    .feedback-form .feedback-sumbit button {
      font-weight: 500;
      font-size: 15px;
    }
  }

  @media screen and (max-width: 800px) {
    .feedback-form-container.visible {
      width: 70%;
    }
  }

  @media screen and (max-width: 720px) {
    .feedback-form-container.visible {
      width: 80%;
    }

    .HeaderFeedBackInputs .form-group input {
      font-size: 15px;
    }

    .form-group input,
    .form-group textarea {
      font-size: 15px !important;
    }

    .feedback-form .form-group textarea {
      font-size: 15px !important;
    }
  }

  @media screen and (max-width: 600px) {
    .feedback-form-container.visible {
      width: 100%;
      right: 0;
      height: 100%;
      z-index: 2000;
      bottom: 0;
    }

    .feedback-close-btn {
      display: block !important;
    }

    .feedback-title {
      border-radius: 0px;
    }

    .HeaderFeedBackInputs .form-group {
      width: 100%;
    }

    .HeaderFeedBackInputs {
      flex-direction: column;
    }

    .form-group textarea {
      height: 150px;
    }

    .feedback-form {
      width: 100%;
      height: 100%;
      border-radius: 0;
    }

    .feedback-form .form-group label {
      font-size: 14px;
      position: relative;
      right: -5px;
    }

    .HeaderFeedBackInputs .form-group input {
      padding: 2.5% 10px;
    }
  }