.ConteinerEvents h5 {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 19.07px;
  padding-left: 2%;
  padding-top: 2%;
  position: relative;
  color: #9b9797;
}

.ConteinerEvents h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  font-weight: 500;
  line-height: 42.91px;
  text-align: left;
  color: #000000;
  padding: 2%;
}

.ConteinerEvents h5 span {
  color: black;
}

.ContentServices {
  width: 96%;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: calc(6% / 3);
}

.BoxService {
  width: 32%;
  font-family: "Inter", sans-serif;
  height: auto;
  margin-bottom: 3vh;
  min-height: 320px;
  padding-bottom: 18%;
  background-position: center;
  background-size: cover;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}

.BoxService h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: white;
  padding-left: 3%;
  position: absolute;
  bottom: 10%;
}

.BackroundOpacityServices ul li {
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  color: white;
  display: flex; /* Используем Flexbox для выравнивания */
  align-items: flex-start; /* Выравниваем элементы по началу строки */
  margin-bottom: 5px;
}
.BackroundOpacityServices ul li::before{
  flex-shrink: 0 !important;
}

.BackroundOpacityServices ul li::before {
  content: "•";
  padding-right: 5px;
  font-size: 20px;
  position: relative;
  top: calc(5 / 2 * -1px);
}

.BackroundOpacityServices {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  padding-top: 5%;
  padding-left: 4%;
  background: rgba(0, 0, 0, 0.548);
  transition: opacity 0.3s linear;
}

.BoxService:hover h1 {
  opacity: 0;
}

.BoxService:hover .BackroundOpacityServices {
  opacity: 1;
}

.ConteinerEvents {
  padding-bottom: 5%;
  padding-top: 95px;
}

.BoxService #titleservices {
  margin-left: 2%;
}

.BoxService #titleservices::before {
  content: '';
  width: 12px;
  height: 3px;
  background-color: #faaa4d;
  position: absolute;
  left: 0px;
  top: 14px;
}

@media screen and (max-width: 1380px) {
  .BackroundOpacityServices ul li{
    font-size: 18px;
  }
}

@media screen and (max-width: 1242px) {
  .BoxService {
    width: 49%;
    overflow: hidden;
  }
}

@media screen and (max-width: 984px) {
  .BoxService {
    min-height: 250px;
  }

  .BoxService h1 {
    font-size: 20px;
  }

  .ConteinerEvents h5 {
    font-size: 16px;
  }

  .BackroundOpacityServices ul li {
    font-size: 17px;
  }
}

@media screen and (max-width: 980px) {
  .BoxService {
    width: 95%;
    min-height: 325px;
  }

  .ContentServices {
    justify-content: center;
  }

  .ConteinerEvents h2 {
    padding-bottom: 15px;
  }
}

@media screen and (max-width: 600px) {
  .BoxService {
    min-height: 300px;
  }
}

@media screen and (max-width: 500px) {
  .BoxService {
    min-height: 280px;
  }

  .BackroundOpacityServices ul li::before {
    font-size: 16px
  }

  .BackroundOpacityServices ul li{
    font-size: 14px;
    line-height: 18px;
  }

  .BoxService h1 {
    font-size: 18px;
  }

  .BoxService #titleservices::before {
    content: '';
    width: 10px;
    height: 3px;
    background-color: #faaa4d;
    position: absolute;
    left: -2px;
    top: 13px;
  }
}

@media screen and (max-width: 850px) {
  .ConteinerEvents {
    padding-top: 90px;
  }
}

.BoxService {
  animation: animate 0.7s ease-out;
}

@keyframes animate {
  from {
    opacity: 0;
    transform: translateY(70px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

#services {
  animation: animate 0.7s ease-out;
}

#services::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 5px;
  position: relative;
  background-color: #faaa4d;
  left: -10px;
  top: -10px;
}

@media screen and (max-width: 900px) {
  #services::before {
    width: 18px;
    height: 4px;
    left: -5px;
    top: -8px;
  }
}